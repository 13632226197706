import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { colors } from '../utils/siteVars';

const StyledCard = styled.div<{ inner?: boolean }>`
  background: ${colors.white};
  padding: 1.3rem;
  border-radius: 10px;
  ${(props) =>
    props.inner
      ? 'background: ' + colors.lightGrey + '; margin-top: 1rem;'
      : 'box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.1); margin-bottom: 1rem;'}
`;

type Props = {
  inner?: boolean;
  className?: string;
};

const Card = ({ className, inner, children }: PropsWithChildren<Props>) => {
  return (
    <StyledCard className={className} inner={inner}>
      {children}
    </StyledCard>
  );
};

export default Card;

import axios from 'axios';
import { do_url } from './siteVars';
import { captureException } from '@sentry/gatsby';
import { GlobalState } from '../types/state.types';
import { Dispatch } from 'react';
import { Action, ActionType } from '../types/dispatch.types';

type Props = {
  state?: Partial<GlobalState>;
  dispatch?: Dispatch<Action>;
  path: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  successText?: string;
  errorText?: string;
  body?: Record<string, any> | string;
  isDoServer?: boolean;
  skipLoader?: boolean;
};

type Return<T> = Promise<{
  err?: unknown;
  data?: T;
}>;

export const request = async <T>({
  state,
  dispatch,
  path,
  method = 'GET',
  successText,
  errorText,
  body,
  isDoServer,
  skipLoader,
}: Props): Return<T> => {
  const action = (payload: Action) => {
    if (dispatch) dispatch(payload);
  };
  try {
    const base = isDoServer ? do_url : process.env.GATSBY_BACKEND_API_URL;
    const pathname =
      isDoServer && state?.token ? path.replace('{token}', state.token) : path;
    const url = `${base}${pathname}`;
    const headers: RequestInit['headers'] = {
      'Content-Type': 'application/json',
    };
    if (state?.token) headers.Authorization = `Bearer ${state.token}`;
    const options: RequestInit = {
      method,
      headers,
    };
    if (body) options.body = JSON.stringify(body);
    if (!skipLoader) action({ type: ActionType.LOADING, value: true });
    const response = await fetch(url, options);
    const result = await response.json();
    if (!response.ok) throw result;
    if (successText) action({ type: ActionType.ALERT, content: successText });
    return { data: result };
  } catch (err) {
    console.error(err);
    captureException(err);
    action({ type: ActionType.ALERT, content: errorText || 'Något gick fel' });
    return { err };
  } finally {
    if (!skipLoader) action({ type: ActionType.LOADING, value: false });
  }
};

export const progressRequest = async <T>({
  state,
  dispatch,
  path,
  method = 'GET',
  successText,
  errorText,
  body,
}: Props): Return<T> => {
  const action = (payload: Action) => {
    if (dispatch) dispatch(payload);
  };
  const onUploadProgress = ({
    loaded,
    total,
  }: {
    total: number;
    loaded: number;
  }) => {
    const progress = Math.round((loaded * 100) / total);
    action({
      type: ActionType.LOADING_PROGRESS,
      progress: progress,
      value: true,
    });
  };
  try {
    let pathname = path;
    if (path.includes('{token}')) {
      if (!state?.token) throw new Error('Token is missing from state');
      pathname = path.replace('{token}', state.token);
    }

    const url = `${do_url}${pathname}`;
    const headers: RequestInit['headers'] = {
      'content-type': 'multipart/form-data',
    };
    if (state?.token) headers.Authorization = `Bearer ${state.token}`;
    const options = {
      headers,
      onUploadProgress,
    };
    action({ type: ActionType.LOADING_PROGRESS, progress: 1, value: true });
    let promise;
    // @ts-ignore
    if (body) promise = axios[method.toLowerCase()](url, body, options);
    // @ts-ignore
    else promise = axios[method.toLowerCase()](url, options);
    const response = await promise;
    if (successText) action({ type: ActionType.ALERT, content: successText });
    return { data: response.data };
  } catch (err) {
    captureException(err);
    console.error(err);
    action({ type: ActionType.ALERT, content: errorText || 'Något gick fel' });
    return { err };
  } finally {
    action({ type: ActionType.LOADING_PROGRESS, progress: null, value: false });
  }
};
